/***
 * Copyright (C) 2025 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Update Page Approval Form
 */
import {Person} from '@mui/icons-material';
import {Button, InputAdornment, SxProps, TextField, Typography} from '@mui/material';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {colors, spacing} from '@vst/beam';
import moment, {Moment} from 'moment';
import React, {useCallback, useState} from 'react';
import {PackageData} from '../api/queries/packages';
import {RejectUpdateDialog} from './RejectUpdateDialog';
import TailListDropdown from './TailListDropdown';
import {Dispatcher, DISPATCHER_PRIMARY_PURPLE} from '../theme/Colors';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {outlinedInputClasses} from '@mui/material/OutlinedInput';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {formatPackageType} from '../utils/gridUtils';
import { strings } from '../locale/strings';
import MultiSelectorDropdown from './MultiSelectorDropdown';

export const popperSx: SxProps = {
  '& .clientJss-MuiDayPicker-weekDayLabel, & .clientJss-MuiPickersDay-root': {
    fontSize: 16,
    '&.Mui-selected': {
      backgroundColor: DISPATCHER_PRIMARY_PURPLE
    }
  }
};

const lockedPackageTypes = ['portal', 'ife']; // Changing tails or deployment date for these package types isn't allowed

export const ApprovalForm: React.FC<{
  approverName: string;
  firstPossibleDeploymentDate: Moment;
  possibleFleetsTails: Record<string,string[]>;
  update: PackageData;
  onApprove: (approvedTails: Array<string>, deploymentDate: Moment) => void;
  onReject: (rejectionReason: string) => void;
}> = ({ approverName, firstPossibleDeploymentDate, possibleFleetsTails, update, onApprove, onReject }) => {
  const updateDeploymentDate = moment(update.deployment_date);
  const defaultDeploymentDate = moment.max([firstPossibleDeploymentDate, updateDeploymentDate]);
  const defaultSelectedFleetsTails = lockedPackageTypes.includes(update.package_type)
    ? possibleFleetsTails
    : {}
  const [selectedFleetsTails, setSelectedFleetsTails] = useState<Record<string,string[]>>(defaultSelectedFleetsTails);
  const [deploymentDate, setDeploymentDate] = useState<Moment>(defaultDeploymentDate);
  const [noTailsSelectedError, setNoTailsSelectedError] = useState<boolean>(false);

  const selectedTails = Object.values(selectedFleetsTails).flat();
  const formIsValid = selectedTails.length> 0; //TODO: check that deploymentDate is valid
  const onApproveClick = useCallback(() => {
    if (formIsValid) {
      setNoTailsSelectedError(false);
      onApprove(selectedTails, deploymentDate);
    } else {
      setNoTailsSelectedError(true);
    }
  }, [deploymentDate, formIsValid, onApprove, selectedTails]);
  const [rejectUpdateDialogOpen, setRejectUpdateDialogOpen] = useState(false);
  const [datePickerFocus, setDatePickerFocus] = useState(false);

  return (
    <>
      <div style={{width: '100%', display: 'flex', flexDirection: 'column', gap: spacing[32]}}>
        {noTailsSelectedError && (
          <Typography variant="body1" color={colors.alert[400]}>
            Please fill out the missing information.
          </Typography>
        )}
        <div style={{display: 'flex', flexDirection: 'row', gap: spacing[24]}}>
          <TextField
            disabled
            label="Approver"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Person sx={{color: Dispatcher.AccessibleGrayIconsAndBorders, fontSize: spacing[24]}} />
                </InputAdornment>
              )
            }}
            size="medium"
            variant="outlined"
            defaultValue={approverName}
            fullWidth
            sx={{
              [`& .${outlinedInputClasses.root}`]: {
                paddingLeft: `${spacing[12]}`
              },
              [`& .${outlinedInputClasses.input}`]: {
                padding: `${spacing[16]} ${spacing[12]} ${spacing[16]} 0px`
              }
            }}
          />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              disablePast
              label="Start of deployment (UTC Time)"
              minDate={firstPossibleDeploymentDate}
              value={deploymentDate}
              PaperProps={{
                sx: popperSx
              }}
              onOpen={() => setDatePickerFocus(true)}
              onClose={() => setDatePickerFocus(false)}
              OpenPickerButtonProps={{
                className: 'calendarButton',
                disabled: lockedPackageTypes.includes(update.package_type),
                sx: {
                  padding: `0 0 0 ${spacing[12]}`,
                  '&:hover': {
                    background: 'none'
                  },
                  '& svg': {
                    fontSize: spacing[24],
                    color: Dispatcher.AccessibleGrayIconsAndBorders
                  },
                  '& svg:hover': {
                    color: Dispatcher.AccessibleGray
                  },
                  '& svg:active': {
                    color: colors.violet[600]
                  }
                }
              }}
              InputAdornmentProps={{position: 'start'}}
              components={{
                OpenPickerIcon: CalendarTodayIcon
              }}
              disabled={lockedPackageTypes.includes(update.package_type)}
              onChange={date => {
                setDeploymentDate(date ?? defaultDeploymentDate);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  focused={datePickerFocus}
                  onFocus={() => setDatePickerFocus(true)}
                  onBlur={() => setDatePickerFocus(false)}
                  fullWidth
                  required={!lockedPackageTypes.includes(update.package_type)}
                  helperText={
                    lockedPackageTypes.includes(update.package_type)
                      ? 'Date selected by your Technical Account Manager'
                      : null
                  }
                  sx={{
                    [`& .${outlinedInputClasses.root}`]: {
                      paddingLeft: spacing[12]
                    },
                    [`& .${outlinedInputClasses.input}`]: {
                      padding: `${spacing[16]} ${spacing[12]} ${spacing[16]} 0px`
                    }
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </div>
        {!lockedPackageTypes.includes(update.package_type) ? (
          <MultiSelectorDropdown
          prompt={strings.SelectTails}
          helperText={noTailsSelectedError ? strings.SelectTails : ''}
          items={possibleFleetsTails}
          selectedItems={selectedFleetsTails}
          setSelectedItems={items => {
            setSelectedFleetsTails(items);
          }}
          error={noTailsSelectedError}
          label={strings.Tails}
          />
        ) : null}
        {selectedTails.length > 0 ? (
          <TailListDropdown tails={selectedTails} selectedFleetsTails={selectedFleetsTails} showFleet={true} tailListLinkPosition="top" />
        ) : null}
        <div style={{display: 'flex', flexDirection: 'row', gap: spacing[8]}}>
          <Button size="large" variant="contained" onClick={onApproveClick}>
            Approve update
          </Button>
          <Button
            size="large"
            variant="outlined"
            onClick={() => {
              setRejectUpdateDialogOpen(true);
            }}
          >
            Reject update
          </Button>
        </div>
      </div>
      <RejectUpdateDialog
        updateName={`${formatPackageType(update.package_type)} ${update.name}`}
        open={rejectUpdateDialogOpen}
        updateOpen={setRejectUpdateDialogOpen}
        onRejectClick={function (reason: string): void {
          onReject(reason);
        }}
      />
    </>
  );
};
