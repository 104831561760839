/***
 * Copyright (C) 2025 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Aircraft selector component
 */
import MultiSelectorDropdown from './MultiSelectorDropdown';
import {TailListLink} from './TailListLink';
import React from 'react';
import {strings} from '../locale/strings';
import {SxProps, Theme} from '@mui/material';

export const AircraftSelector: React.FC<{ 
  label: string;
  selectAllLabel: string;
  prompt: string;
  helperTextSx?: SxProps<Theme> | undefined;
  helperText?: React.ReactNode;
  tailListText?: string;
  fullWidth?: boolean;
  airlineFleetsTails: Record<string, string[]>;
  selectedAircraft:  Record<string, string[]>;
  setSelectedAircraft: (selectedAircraft: Record<string, string[]>) => void;
}> = ({
  label,
  prompt,
  selectAllLabel,
  helperText,
  helperTextSx,
  tailListText,
  fullWidth,
  airlineFleetsTails,
  selectedAircraft,
  setSelectedAircraft
}) => {
  const fleetList = Object.keys(airlineFleetsTails)
  if (fleetList.length === 0) { 
    return null;
  }
  const selectedTails: string[] = Object.values(selectedAircraft).flat();
  return (
    <MultiSelectorDropdown
      selectAllByDefault={false}
      fullWidth={fullWidth}
      label={label}
      prompt={selectedTails.length > 0? strings.NTails.replace('%n%', selectedTails.length.toString()) : prompt}
      selectAllLabel={selectAllLabel}
      renderFooter={() => <TailListLink tails={selectedTails} text={tailListText} />}
      helperTextSx={helperTextSx}
      helperText={helperText}
      items={airlineFleetsTails}
      selectedItems={selectedAircraft}
      setSelectedItems={setSelectedAircraft}
    />
  );
};
