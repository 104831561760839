/***
 * Copyright (C) 2025 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: UpdatesTable component
 */

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Box, TableRow as MUITableRow, Table, TableCell, TableContainer, TableHead, Typography} from '@mui/material';
import {colors, spacing} from '@vst/beam';
import {PackageData, PackageStatuses} from '../api/queries/packages';
import {Dispatcher} from '../theme/Colors';
import {formatDate, formatPackageType} from '../utils/gridUtils';
import IconTextInABox from './IconTextInABox';
import StatusAndTailsCount from './StatusAndTailsCount';
import {HeaderCell, TableBody, TextCell} from './Tables';
import {FilterRow} from '@viasat/insights-components/dist/components/filterSelector/FilterSelectorContainer';
import {filterUpdates} from './UpdatesFilter';

const UpdatesTableHead: React.FC<{displayAirlineColumn: boolean}> = ({displayAirlineColumn}) => (
  <TableHead>
    <MUITableRow>
      <HeaderCell text="Status" />
      <HeaderCell text="Type" />
      <HeaderCell text="Update" />
      {displayAirlineColumn && <HeaderCell text="Airline" />}
      <HeaderCell text="Released" />
      <HeaderCell text="Release notes" sx={{width: '99%'}} />
      <HeaderCell text="" />
    </MUITableRow>
  </TableHead>
);

const TableRow: React.FC<{
  update: PackageData;
  onClick: (update: PackageData) => void;
  allowApproval: boolean;
  displayAirlineColumn: boolean;
  isAdmin: boolean;
}> = ({update, onClick, allowApproval, displayAirlineColumn, isAdmin}) => (
  <MUITableRow sx={{'&:hover': {backgroundColor: colors.gray[100]}, cursor: 'pointer'}} onClick={() => onClick(update)}>
    <TableCell align="left" sx={{whiteSpace: 'nowrap'}}>
      <StatusAndTailsCount
        status={update.status}
        allowApproval={allowApproval}
        approvedTails={update.approved_tails}
        applicableTails={Object.values(update.applicable_tails).flat()}
      />
    </TableCell>
    <TextCell text={formatPackageType(update.package_type)} />
    <TextCell text={update.name} />
    {displayAirlineColumn && <TextCell text={update.airline_name} />}
    <TextCell text={formatDate(update.created_timestamp)} />
    <TextCell text={update.notes} ellipsis />
    <TableCell align="right" sx={{whiteSpace: 'nowrap'}}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'end',
          gap: spacing[8]
        }}
      >
        <Typography
          color={
            allowApproval || (update.status === PackageStatuses.NO_APPLICABLE_TAILS && isAdmin)
              ? 'primary'
              : Dispatcher.AccessibleGrayIconsAndBorders
          }
          variant="button"
        >
          {allowApproval ? <>Approve</> : <></>}
          {update.status === PackageStatuses.NO_APPLICABLE_TAILS && isAdmin ? (
            <>Add tails to Campaign</>
          ) : update.status === PackageStatuses.NO_APPLICABLE_TAILS ? (
            <>Adding tail information</>
          ) : (
            <></>
          )}
        </Typography>
        <ArrowForwardIcon
          htmlColor={
            allowApproval || (update.status === PackageStatuses.NO_APPLICABLE_TAILS && isAdmin)
              ? colors.violet[500]
              : Dispatcher.AccessibleGrayIconsAndBorders
          }
          sx={{verticalAlign: 'middle', width: 24, height: 24}}
        />
      </Box>
    </TableCell>
  </MUITableRow>
);

// Sort descending by creation time
const defaultSortFn = (a: PackageData, b: PackageData) =>
  new Date(b.created_timestamp).getTime() - new Date(a.created_timestamp).getTime();

/**
 * Table of Update Packages
 */
const UpdatesTable: React.FC<{
  updates?: PackageData[];
  isLoading: boolean;
  error: boolean;
  onClickUpdateRow: (update: PackageData) => void;
  displayAirlineColumn: boolean;
  allowApproval: (update: PackageData) => boolean;
  isAdmin: boolean;
  filters: Array<FilterRow>;
}> = ({updates, isLoading, error, onClickUpdateRow, displayAirlineColumn, allowApproval, isAdmin, filters}) => {
  const numCols = displayAirlineColumn ? 6 : 5;
  return (
    <TableContainer>
      <Table>
        <UpdatesTableHead displayAirlineColumn={displayAirlineColumn} />
        <TableBody
          items={filters.length > 0 ? filterUpdates(filters, updates || []) : updates}
          isLoading={isLoading}
          error={error}
          numCols={numCols}
          defaultSortFn={defaultSortFn}
          renderRow={update => (
            <TableRow
              key={update.id}
              update={update}
              onClick={onClickUpdateRow}
              allowApproval={allowApproval(update)}
              displayAirlineColumn={displayAirlineColumn}
              isAdmin={isAdmin}
            />
          )}
          renderEmpty={() => (
            <MUITableRow>
              <TableCell colSpan={numCols}>
                <IconTextInABox icon="search" text="No updates found. Try adjusting your filters." />
              </TableCell>
            </MUITableRow>
          )}
        />
      </Table>
    </TableContainer>
  );
};
export default UpdatesTable;
