/***
 * Copyright (C) 2025 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Available Updates Filter
 */
import {FilterSelector} from '@viasat/insights-components';
import {formatPackageType} from '../utils/gridUtils';
import {FilterRow} from '@viasat/insights-components/dist/components/filterSelector/FilterSelectorContainer';
import {PackageData, PackageStatuses, packageTypes} from '../api/queries/packages';
import React, {useEffect, useState} from 'react';
import {Dispatcher} from '../theme/Colors';
import {FilterSelectorOption} from '../types';

/**
 * Determines if the update passes the status filter.
 *
 * @param filter The status filter row.
 * @param update The update.
 * @return Whether the update passes the filter.
 */
const filterStatuses = (filter: FilterRow, update: PackageData): boolean => {
  return (
    filter.rangeOptionsKeys?.some((status: string) => {
      switch (status) {
        case 'available':
          return [PackageStatuses.AVAILABLE, PackageStatuses.PARTIALLY_APPROVED].includes(update.status);
        case 'unavailable':
          return [PackageStatuses.CLOSED, PackageStatuses.NO_APPLICABLE_TAILS].includes(update.status);
        default:
          return status.toLowerCase() === update.status.toLowerCase();
      }
    }) || filter.rangeOptionsKeys?.length === 0
  );
};

/**
 * Determines if the update passes the applicable tails filter.
 *
 * @param filter The applicable tails filter row.
 * @param update The update.
 * @return Whether the update passes the filter.
 */
const filterApplicableTails = (filter: FilterRow, update: PackageData): boolean => {
  return (
    filter.rangeOptionsKeys?.some((tail: string) => Object.values(update.applicable_tails).flat().includes(tail)) ||
    filter.rangeOptionsKeys?.length === 0
  );
};

/**
 * Determines if the update passes the update type filter.
 *
 * @param filter The udpate type filter row.
 * @param update The update.
 * @return Whether the update passes the filter.
 */
const filterUpdateType = (filter: FilterRow, update: PackageData): boolean => {
  return (
    filter.rangeOptionsKeys?.some(
      (updateType: string) => update.package_type.toLowerCase() === updateType.toLowerCase()
    ) || filter.rangeOptionsKeys?.length === 0
  );
};

/**
 * Determines if the update passes the update name filter.
 *
 * @param filter The udpate name filter row.
 * @param update The update.
 * @return Whether the update passes the filter.
 */
const filterUpdateName = (filter: FilterRow, update: PackageData): boolean => {
  return filter.rangeOptionsKeys?.some((name: string) => update.name === name) || filter.rangeOptionsKeys?.length === 0;
};

/**
 * Apply filters to available updates.
 *
 * @param filters Array of filters.
 * @param updates Array of available updates.
 * @return Array of available updates which pass all filters.
 */
export const filterUpdates = (filters: FilterRow[], updates: PackageData[]): PackageData[] => {
  return updates.filter(update => {
    let included: boolean = true;

    for (const filter of filters) {
      const domain = filter.domainOptionsKey;

      switch (domain) {
        case 'updateName':
          included = included && filterUpdateName(filter, update);
          break;
        case 'updateType':
          included = included && filterUpdateType(filter, update);
          break;
        case 'applicableTails':
          included = included && filterApplicableTails(filter, update);
          break;
        case 'status':
          included = included && filterStatuses(filter, update);
          break;
      }
    }

    return included;
  });
};

export const UpdatesFilter: React.FC<{
  updates?: PackageData[];
  filters: Array<FilterRow>;
  onFiltersChange: (filters: Array<FilterRow>) => void;
}> = ({updates, filters, onFiltersChange}) => {
  const [updateNameOptions, setUpdateNameOptions] = useState<Array<FilterSelectorOption>>([]);
  const [applicableTailOptions, setApplicableTailOptions] = useState<Array<FilterSelectorOption>>([]);
  const updateTypeOptions: Array<FilterSelectorOption> = packageTypes.map(packageType => ({
    optionKey: packageType,
    optionValue: formatPackageType(packageType)
  }));
  const statusOptions: Array<FilterSelectorOption> = [
    {
      optionKey: 'available',
      optionValue: 'Available'
    },
    {
      optionKey: PackageStatuses.FULLY_APPROVED,
      optionValue: 'Fully Approved'
    },
    {
      optionKey: 'unavailable',
      optionValue: 'Unavailable'
    },
    {
      optionKey: PackageStatuses.REJECTED,
      optionValue: 'Rejected'
    }
  ];

  const domainOptions = [
    {
      optionKey: 'applicableTails',
      optionValue: 'Applicable Tails'
    },
    {
      optionKey: 'updateType',
      optionValue: 'Type'
    },
    {
      optionKey: 'updateName',
      optionValue: 'Update'
    },
    {
      optionKey: 'status',
      optionValue: 'Status'
    }
  ];

  const rangeOptions = {
    applicableTails: applicableTailOptions,
    updateType: updateTypeOptions,
    updateName: updateNameOptions,
    status: statusOptions
  };

  // Update the filter options when the updates data changes
  useEffect(() => {
    const nonUniqueTails: Array<string> = [];
    const nameOptions: Array<FilterSelectorOption> = [];
    const tailOptions: Array<FilterSelectorOption> = [];

    // Create selector options from available updates information
    updates?.forEach(update => {
      nonUniqueTails.push(...Object.values(update.applicable_tails).flat());
      nameOptions.push({
        optionKey: update.name,
        optionValue: update.name
      });
    });

    // Make sure there are no duplicate tail options
    new Set(nonUniqueTails).forEach(tail => {
      tailOptions.push({
        optionKey: tail,
        optionValue: tail
      });
    });

    setUpdateNameOptions(nameOptions);
    setApplicableTailOptions(tailOptions);
  }, [updates]);

  return (
    <FilterSelector
      getFullElementId={(name: string, type: string) => `FilterSelector__${name}-${type}`}
      filters={filters}
      domainOptions={domainOptions}
      rangeOptions={rangeOptions}
      onFiltersChange={onFiltersChange}
      addFilterDisabled={filters.length >= 4}
      addFilterDisabledColor={Dispatcher.AccessibleGrayIconsAndBorders}
    />
  );
};
