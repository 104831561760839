/***
 * Copyright (C) 2025 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Cpp Details Page
 */
import styled from '@emotion/styled';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {ShareLink} from '@viasat/insights-components';
import {colors, spacing} from '@vst/beam';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  SxProps,
  TextField,
  Theme,
  Typography
} from '@mui/material';
import {ExpandMore as ExpandMoreIcon, Delete as DeleteIcon, ContentCopy} from '@mui/icons-material';
import CustomButton from '../../components/CustomButton';
import {v4 as uuidv4} from 'uuid';
import {spaComs} from '@viasat/insights-spa-package';

import BackButton from '../../components/BackButton';
import CppInformationTable from '../../components/CppDetailsTable';
import ReviewIntakeDetails from '../../components/CppIntakeRequestReview';
import ErrorComponent from '../../components/ErrorComponent';
import {Section} from '../../components/Section';
import {ModalDialog} from '../../components/ModalDialog';

import {PATHS} from '../../paths';
import PageContainerTemplate from '../../theme/PageContainerTemplate';

import {getCurrentUrlPath} from '../../utils/linkUtils';
import useBearStore from '../../utils/stores/useBearStore';
import {useCppRequestDraftStore} from '../../utils/stores/cppRequestDraftStore';
import {mapDraftToFetchedFormat, mapFetchedToDraftFormat} from '../../utils/cppUtils';

import {strings} from '../../locale/strings';
import qs from 'qs';
import {statusMap} from '../../components/CppRequestHistoryTable';
import {convertToFinalType} from '../Cpp/CppRequestSteps/CppRequestShared';
import {CppRequest, CppRequestDraft} from '../Cpp/types';
import CppHelmValues from './CppHelmValues';
import {Alert} from '../../components/Alert';
import CppDeploymentButton from './CppDeploymentButton';
import {useCppRequestDetails} from '../../api/queries/useCppRequestDetails';
import {AircraftSelector} from '../../components/AircraftSelector';
import {useAirlineTails} from '../../api/queries/useAirlineTails';
import TailList from '../../components/TailList';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import {Dispatcher} from '../../theme/Colors';
import {popperSx} from '../../components/ApprovalForm';
import Divider from '@mui/material/Divider';
import {alpha, Stack} from '@mui/system';

const ACCORDION_DEFAULT_HEIGHT = '104px';
const AircraftSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[32]};
  width: 625px;
  align-items: flex-start;
  align-self: stretch;
  flex-grow: 0;
  margin-bottom: ${spacing[24]};
`;

const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 625px;
  margin-top: ${spacing[24]};
`;

const AccordionParentContainer = styled.div`
  display: flex;
  padding: 0 ${spacing[32]};
  flex-direction: column;
`;
const CppDetailsAccordion: React.FC<{
  children: JSX.Element[];
  expanded: boolean;
  onChange: () => void;
  dataTestId: string;
  sx?: SxProps<Theme>;
}> = ({children, expanded, onChange, dataTestId, sx}) => (
  <Accordion
    square={true}
    elevation={0}
    expanded={expanded}
    onChange={onChange}
    data-testid={dataTestId}
    sx={{
      ...sx
    }}
  >
    {children}
  </Accordion>
);
const CppDetailsAccordionSummary: React.FC<{
  children?: JSX.Element;
  title: string;
  bodyText: string;
  sx?: SxProps<Theme>;
}> = ({children, title, bodyText, sx}) => {
  return (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon sx={{p: spacing[12], fontSize: 24, fontWeight: 'bold'}} />}
      sx={{p: 0, '&:hover': {backgroundColor: colors.gray[100]}, ...sx}}
    >
      <Section title={title} bodyText={bodyText} layout="vertical" />
      {children}
    </AccordionSummary>
  );
};

export type BannerDataProps = {
  show: boolean;
  message: string;
  status: 'error' | 'success';
};

const Banner: React.FC<
  BannerDataProps & {
    onClickClose: () => void;
  }
> = ({show, message, status, onClickClose}) =>
  show ? (
    <Alert
      data-testid="cpp-details-banner"
      bodyText={message}
      status={status}
      closing={true}
      onClickClose={onClickClose}
    />
  ) : (
    <></>
  );

const CppIntakeDetails: React.FC = () => {
  // Use the useBearStore hook to get the airlines state
  const {airlines, isInternal, isDeployer} = useBearStore(state => ({
    airlines: state.airlines,
    isInternal: state.init.isInternal,
    isDeployer: state.init.isDeployer
  }));
  const navigate = useNavigate();
  const {drafts, deleteDraft, updateDraft} = useCppRequestDraftStore();

  const {id} = useParams<string>();
  const {refetch: refetchCppDetails, status, data} = useCppRequestDetails({id: id && drafts.has(id) ? undefined : id});

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openDuplicateModal, setOpenDuplicateModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<{title: string; message: string; okText: string}>({
    title: '',
    message: '',
    okText: ''
  });

  const [panelExpanded, setPanelExpanded] = React.useState({
    addAircraftPanel: true,
    detailsExpandedPanel: false,
    helmValuesPanel: false
  });

  const [bannerProps, setBannerProps] = useState<BannerDataProps>({show: false, message: '', status: 'success'});
  const [selectedAircraft, setSelectedAircraft] = useState<Record<string, string[]>>({});
  const [desiredDeploymentDate, setDesiredDeploymentDate] = useState<string>(moment().utc().format('MM/DD/YYYY'));
  const [datePickerFocus, setDatePickerFocus] = useState(false);

  const handleDuplicate = (cppRequestData: CppRequest) => {
    // Generate a new ID for the duplicate draft
    const newId = uuidv4();

    const duplicateData = {...cppRequestData};

    // Save the duplicate data as a draft
    updateDraft(newId, mapFetchedToDraftFormat(duplicateData));

    // Navigate to the beginning of the intake form with the new ID
    navigate(`${PATHS.cppRequestBase}/${newId}`);
  };

  // Try to get the CPP request data from the drafts using the id. If it doesn't exist in drafts, find it in the fetched data.
  const cppRequestData: CppRequest | undefined =
    id && drafts.has(id) ? mapDraftToFetchedFormat(id, drafts.get(id) as CppRequestDraft) : data;

  const urlPath = getCurrentUrlPath();
  const urlQuery = qs.stringify({}, {arrayFormat: 'comma'});
  const readableStatus = cppRequestData ? statusMap[cppRequestData.status] : undefined;
  const isDraft = cppRequestData?.status === 'draft';
  const isWaitingForHelm = cppRequestData?.status === 'submitted';
  const isReadyToDeploy = cppRequestData?.status === 'ready_to_deploy';

  const {data: airlineFleetsTails} = useAirlineTails(
    cppRequestData?.airlineCode,
    {
      onlyLabTails: cppRequestData?.labOnly || false
    },
    isReadyToDeploy && (isDeployer || isInternal) && Boolean(cppRequestData?.airlineCode)
  );

  // Expand the Helm and Values accordion if the request is in the 'submitted' status
  useEffect(() => setPanelExpanded(prev => ({...prev, helmValuesPanel: true})), [isWaitingForHelm]);

  // Swap airlines if the current airline is not in the list of available airlines
  useEffect(() => {
    if (!airlines || !data?.airlineCode) return;

    if (!airlines.includes(data.airlineCode)) {
      spaComs.sendGroupChange(data.airlineCode);
    }
  }, [data?.airlineCode, airlines]);

  return status === 'error' ? (
    <ErrorComponent
      headerError={strings.CppIntakeRequestDetailsSection.ThirdPartyApplicationDetails}
      bodyErrorMessage={strings.CppIntakeRequestDetailsSection.NoDataError}
      dataTestId="no-data-details-container"
    />
  ) : (
    <>
      <PageContainerTemplate
        title={cppRequestData?.application}
        subtitle={readableStatus}
        isSubtitleLoading={false}
        getFullElementId={(name: string, type: string) => `${name}-${type}`}
        disableSpaGroupSelector={true}
        preTitleStack={[
          <BackButton label={'Back'} key="backButton" onClick={() => navigate(PATHS.cppRequestHistory)} />
        ]}
        leftStack={[]}
        rightStack={[
          isDraft ? (
            <CustomButton
              getFullElementId={(name: string, type: string) => `cppDetailsDeleteButton__${name}-${type}`}
              open={openDeleteModal}
              onClick={() => {
                setOpenDeleteModal(true);
                setModalContent({
                  title: strings.CppIntakeRequestDetailsSection.DeleteRequest,
                  message: strings.CppIntakeRequestDetailsSection.ConfirmDelete,
                  okText: strings.CppIntakeRequestDetailsSection.DeleteRequest
                });
              }}
              IconComponent={DeleteIcon}
              buttonText="Delete"
            />
          ) : (
            <>
              <ShareLink
                key="shareLink"
                getFullElementId={(name: string, type?: string) => `update__${name}-${type}`}
                disabled={false}
                urlPath={urlPath}
                urlQuery={urlQuery}
              />
              <CustomButton
                getFullElementId={(name: string, type: string) => `cppDetailsDuplicateButton__${name}-${type}`}
                open={openDuplicateModal}
                onClick={() => {
                  setOpenDuplicateModal(true);
                  setModalContent({
                    title: strings.CppIntakeRequestDetailsSection.DuplicateTitle,
                    message: strings.CppIntakeRequestDetailsSection.DuplicateBody,
                    okText: strings.CppIntakeRequestDetailsSection.DuplicateRequest
                  });
                }}
                IconComponent={ContentCopy}
                buttonText="Duplicate"
              />
            </>
          )
        ]}
      >
        <Banner {...bannerProps} onClickClose={() => setBannerProps({show: false, message: '', status: 'success'})} />
        {cppRequestData && (
          <>
            <CppInformationTable data={cppRequestData} />
            <AccordionParentContainer>
              <CppDetailsAccordion
                expanded={panelExpanded.detailsExpandedPanel}
                onChange={() => setPanelExpanded(prev => ({...prev, detailsExpandedPanel: !prev.detailsExpandedPanel}))}
                dataTestId="accordion-summary"
              >
                <CppDetailsAccordionSummary
                  title="Intake Request Form"
                  bodyText={strings.CppIntakeRequestDetailsSection.ReviewDetails}
                  sx={{height: ACCORDION_DEFAULT_HEIGHT}}
                >
                  <Box sx={{mr: 8, display: 'flex', alignItems: 'center'}}>
                    {isDraft && (
                      <Button
                        size="medium"
                        variant="contained"
                        data-testid="continue-button-summary"
                        onClick={() => {
                          navigate(`${PATHS.cppRequestBase}/${id}`);
                        }}
                      >
                        Continue
                      </Button>
                    )}
                  </Box>
                </CppDetailsAccordionSummary>

                <AccordionDetails data-testid="accordion-details">
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <ReviewIntakeDetails
                      data={convertToFinalType(cppRequestData ?? ({} as CppRequest))}
                      airline={cppRequestData.airlineName}
                    />
                  </Box>
                </AccordionDetails>
              </CppDetailsAccordion>
              <Divider sx={{borderColor: alpha(colors.gray[600], 0.5)}} />
            </AccordionParentContainer>
            {(isWaitingForHelm || isReadyToDeploy) && isInternal && (
              <AccordionParentContainer>
                <CppDetailsAccordion
                  expanded={panelExpanded.helmValuesPanel}
                  onChange={() => setPanelExpanded(prev => ({...prev, helmValuesPanel: !prev.helmValuesPanel}))}
                  dataTestId="accordion-helm-values"
                >
                  <CppDetailsAccordionSummary
                    title="Helm and Values Links"
                    bodyText={strings.CppIntakeRequestDetailsSection.HelmDetails}
                    sx={{height: ACCORDION_DEFAULT_HEIGHT}}
                  ></CppDetailsAccordionSummary>

                  <AccordionDetails data-testid="accordion-helm-details">
                    <CppHelmValues
                      cppRequestData={cppRequestData}
                      isWaitingForHelm={isWaitingForHelm}
                      onSubmitSuccess={async () => {
                        await refetchCppDetails();
                        setBannerProps({
                          show: true,
                          message: strings.CppIntakeRequestDetailsSection.HelmUpdate.success,
                          status: 'success'
                        });
                      }}
                      onSubmitError={() =>
                        setBannerProps({
                          show: true,
                          message: strings.CppIntakeRequestDetailsSection.HelmUpdate.error,
                          status: 'error'
                        })
                      }
                      setBannerProps={setBannerProps}
                    />
                  </AccordionDetails>
                </CppDetailsAccordion>
                <Divider sx={{borderColor: alpha(colors.gray[600], 0.5)}} />
              </AccordionParentContainer>
            )}
            {isReadyToDeploy && (isInternal || isDeployer) && (
              <AccordionParentContainer>
                <CppDetailsAccordion
                  expanded={panelExpanded.addAircraftPanel}
                  onChange={() => setPanelExpanded(prev => ({...prev, addAircraftPanel: !prev.addAircraftPanel}))}
                  dataTestId="accordion-add-cpp-tails"
                >
                  <CppDetailsAccordionSummary
                    title="Applicable Aircraft"
                    bodyText={strings.CppIntakeRequestDetailsSection.ApplicableAircraftAccordionSubheading}
                  />
                  <AccordionDetails sx={{p: 0}} data-testid="accordion-add-cpp-tails-form">
                    <AircraftSelectorContainer>
                      <AircraftSelector
                        airlineFleetsTails={airlineFleetsTails || {}}
                        selectedAircraft={selectedAircraft}
                        setSelectedAircraft={setSelectedAircraft}
                        label={strings.Aircraft}
                        selectAllLabel={strings.AllAircraft}
                        prompt={strings.SelectAircraft}
                        tailListText={strings.AircraftStatusOnThisSelection}
                        fullWidth={true}
                      />
                    </AircraftSelectorContainer>
                    <Stack spacing={1}>
                      {Object.keys(selectedAircraft).map(fleet => {
                        if (selectedAircraft[fleet].length === 0) return null;
                        return (
                          <Stack key={fleet} gap={1}>
                            <Stack direction="row" alignItems={'baseline'} spacing={1}>
                              <Typography variant="h3" color={Dispatcher.AccessibleGray}>
                                {fleet}
                              </Typography>
                              <Typography variant="body1" color={Dispatcher.AccessibleGray}>
                                {selectedAircraft[fleet].length} tails
                              </Typography>
                            </Stack>
                            <TailList tails={Object.values(selectedAircraft[fleet]).flat()} />
                          </Stack>
                        );
                      })}
                    </Stack>
                  </AccordionDetails>
                </CppDetailsAccordion>

                <DatePickerContainer>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      disablePast
                      label="Desired Deployment Date"
                      PaperProps={{
                        sx: popperSx
                      }}
                      value={desiredDeploymentDate}
                      onOpen={() => setDatePickerFocus(true)}
                      onClose={() => setDatePickerFocus(false)}
                      OpenPickerButtonProps={{
                        className: 'calendarButton',
                        sx: {
                          '&:hover': {
                            background: 'none'
                          },
                          '& svg': {
                            fontSize: spacing[24],
                            color: Dispatcher.AccessibleGrayIconsAndBorders
                          },
                          '& svg:hover': {
                            color: Dispatcher.AccessibleGray
                          },
                          '& svg:active': {
                            color: colors.violet[600]
                          }
                        }
                      }}
                      InputAdornmentProps={{position: 'end'}}
                      onChange={date => {
                        setDesiredDeploymentDate(date ?? '');
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          focused={datePickerFocus}
                          onFocus={() => setDatePickerFocus(true)}
                          onBlur={() => {
                            setDatePickerFocus(false);
                          }}
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            placeholder: 'MM/DD/YYY'
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </DatePickerContainer>
                <Divider sx={{paddingTop: '24px', borderColor: alpha(colors.gray[600], 0.5)}} />
                <CppDeploymentButton
                  setBannerProps={setBannerProps}
                  id={cppRequestData.id}
                  selectedTails={Object.values(selectedAircraft).flat()}
                  desiredDeploymentDate={desiredDeploymentDate}
                  data-testid="submit-deployment-button"
                  disabled={Object.values(selectedAircraft).flat().length === 0 || !desiredDeploymentDate}
                />
              </AccordionParentContainer>
            )}
          </>
        )}
      </PageContainerTemplate>
      <ModalDialog
        title={modalContent.title}
        displayState="default"
        okText={modalContent.okText}
        cancelText={strings.Cancel}
        open={openDeleteModal || openDuplicateModal}
        onOk={() => {
          if (openDeleteModal) {
            deleteDraft(id as string);
            navigate(PATHS.cppRequestHistory);
          } else if (openDuplicateModal) {
            handleDuplicate(cppRequestData as CppRequest);
          }
          setOpenDeleteModal(false);
          setOpenDuplicateModal(false);
        }}
        onClose={() => {
          setOpenDeleteModal(false);
          setOpenDuplicateModal(false);
        }}
      >
        <Typography variant="body1">{modalContent.message}</Typography>
      </ModalDialog>
    </>
  );
};

export default CppIntakeDetails;
