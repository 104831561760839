/***
 * Copyright (C) 2025 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Add Applicable Tails component
 */
import styled from '@emotion/styled';
import {Button, Chip, Divider, Typography} from '@mui/material';
import {colors, spacing} from '@vst/beam';
import {Section} from './Section';
import {TailListLink} from '../components/TailListLink';
import {Add, Info, InfoOutlined} from '@mui/icons-material';
import {Dispatcher} from '../theme/Colors';
import {PackageData, PackageStatuses} from '../api/queries/packages';
import {useAirlineTails} from '../api/queries/useAirlineTails';
import {useState} from 'react';
import TailList from './TailList';
import Loading from './Loading';
import {strings} from '../locale/strings';
import {AircraftSelector} from './AircraftSelector';
import {Stack} from '@mui/system';

const AddApplicableAircraftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${spacing[32]};
  gap: ${spacing[32]};
  align-self: stretch;
  flex-grow: 0;
`;

const ApplicableAircraftFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing[32]};
  flex-grow: 0;
`;
const SectionChip = styled.div`
  display: flex;
  margin-left: auto;
`;
const HelperTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing[24]};
  align-items: flex-start;
`;
const HelperIconText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: ${spacing[12]};
`;
const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ApplicableAircraftForm: React.FC<{
  airlineFleetsTails: Record<string, string[]>;
  setShowForm: (showForm: boolean) => void;
  onAddAircraft: (tails: Array<string>) => void;
}> = ({airlineFleetsTails, setShowForm, onAddAircraft}) => {
  const [selectedAircraft, setSelectedAircraft] = useState<Record<string, string[]>>({});
  const selectedAircraftTails = Object.values(selectedAircraft).flat();

  return (
    <ApplicableAircraftFormContainer>
      <AircraftSelector
        label={strings.ApplicableTails}
        selectAllLabel={strings.AllTails}
        prompt={selectedAircraftTails.length === 1 ? strings.OneTail : strings.SelectTail}
        helperTextSx={{marginRight: 0, marginLeft: 0, marginTop: spacing[8]}}
        helperText={
          <HelperTextContainer>
            <HelperIconText>
              <InfoOutlined color="primary" sx={{fontSize: spacing[24]}} />
              <Typography sx={{fontSize: spacing[16], lineHeight: spacing[24], color: Dispatcher.AccessibleGray}}>
                {strings.AddApplicableTailsSection.OpenTailList}
              </Typography>
            </HelperIconText>
            <TailListLink
              tails={selectedAircraftTails}
              text={strings.TailStatus}
              sx={{
                fontSize: spacing[16],
                lineHeight: spacing[24],
                padding: 0,
                '& svg': {
                  fontSize: spacing[24]
                }
              }}
            />
          </HelperTextContainer>
        }
        fullWidth={false}
        airlineFleetsTails={airlineFleetsTails}
        selectedAircraft={selectedAircraft}
        setSelectedAircraft={setSelectedAircraft}
      />
      {Object.keys(selectedAircraft).map(fleet => {
        if (selectedAircraft[fleet].length === 0) return null;
        return (
          <Stack key={fleet} gap={1}>
            <Stack direction="row" alignItems={'baseline'} spacing={1}>
              <Typography variant="h3" color={Dispatcher.AccessibleGray}>
                {fleet}
              </Typography>
              <Typography variant="body1" color={Dispatcher.AccessibleGray}>
                {selectedAircraft[fleet].length} tails
              </Typography>
            </Stack>
            <TailList tails={Object.values(selectedAircraft[fleet]).flat()} />
          </Stack>
        );
      })}
      <Button
        variant="contained"
        size="large"
        disabled={selectedAircraftTails.length <= 0}
        onClick={() => {
          // Add tails
          onAddAircraft(selectedAircraftTails);
          // Reset form
          setSelectedAircraft({});
          setShowForm(false);
        }}
      >
        {strings.AddTails}
      </Button>
    </ApplicableAircraftFormContainer>
  );
};

const AddApplicableTailsSection: React.FC<{
  packageData: PackageData;
  isLoading?: boolean;
  onAddAircraft: (packageId: string, tails: Array<string>) => void;
  isAdminUser: boolean;
}> = ({ packageData, isLoading, onAddAircraft, isAdminUser }) => {


  const packageApplicableTails = Object.values(packageData.applicable_tails).flat();
  const [showForm, setShowForm] = useState<boolean>(packageApplicableTails.length <= 0);
  const {data: airlineFleetsTails} = useAirlineTails(isAdminUser ? packageData.airline_code : undefined, {
    onlyLabTails: false
  });

  const applicableAirlineFleetsTails = airlineFleetsTails
    ? Object.keys(airlineFleetsTails).reduce((acc, fleet) => {
        acc[fleet] = airlineFleetsTails[fleet]?.filter(
          (tailId: string) =>
            !packageApplicableTails.includes(tailId) && !packageData.approved_tails.includes(tailId)
        );
        return acc;
      }, {} as Record<string, string[]>)
    : {};

  const applicableTailIds = Object.values(applicableAirlineFleetsTails).flat();
  const tailsCanBeAddedStates = [
    PackageStatuses.AVAILABLE,
    PackageStatuses.NO_APPLICABLE_TAILS,
    PackageStatuses.PARTIALLY_APPROVED,
    PackageStatuses.FULLY_APPROVED
  ];

  return (
    <AddApplicableAircraftContainer>
      {isLoading ? (
        <LoadingContainer>
          <Loading text={strings.AddingTails}></Loading>
        </LoadingContainer>
      ) : (
        <Section
          title={strings.ApplicableTails}
          bodyText={
            applicableTailIds.length > 0 && tailsCanBeAddedStates.includes(packageData.status)
              ? strings.AddApplicableTailsSection.SelectApplicableTailForUpdate
              : strings.AddApplicableTailsSection.AdditionalTailsCanNotBeAppliedToUpdate
          }
          layout="vertical"
          headerContent={
            isAdminUser &&
            (packageApplicableTails.length <= 0 ? (
              <SectionChip>
                <Chip
                  icon={<Info sx={{color: `${colors.warning[500]} !important`, fontSize: spacing[20]}} />}
                  label={strings.MissingTail}
                  size="medium"
                  sx={{
                    background: colors.alert[100],
                    fontSize: 14,
                    padding: `${spacing[8]} ${spacing[4]}`,
                    radius: spacing[16],
                    lineHeight: spacing[20],
                    color: colors.success[600],
                    width: 'auto'
                  }}
                />
              </SectionChip>
            ) : applicableTailIds.length > 0 && tailsCanBeAddedStates.includes(packageData.status) && !showForm ? (
              <Button
                variant="contained"
                size="medium"
                sx={{
                  padding: `${spacing[8]} ${spacing[24]}`,
                  gap: spacing[8],
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginLeft: 'auto',
                  flex: 'none'
                }}
                onClick={() => {
                  setShowForm(true);
                }}
              >
                <Add />
                <span>{strings.AddTails}</span>
              </Button>
            ) : null)
          }
        >
          {packageApplicableTails.length ? <TailList tails={packageApplicableTails} /> : null}

          {isAdminUser && (
            <>
              {packageApplicableTails.length && showForm ? (
                <>
                  <Divider sx={{width: '100%'}} />
                  <Section title={strings.AddMoreTails} bodyText={''}></Section>
                </>
              ) : null}

              {applicableTailIds.length > 0 &&
              tailsCanBeAddedStates.includes(packageData.status) &&
              (packageApplicableTails.length <= 0 || showForm) ? (
                <ApplicableAircraftForm
                  airlineFleetsTails={applicableAirlineFleetsTails}
                  setShowForm={setShowForm}
                  onAddAircraft={tails => onAddAircraft(packageData.id, tails)}
                />
              ) : null}
            </>
          )}
        </Section>
      )}
    </AddApplicableAircraftContainer>
  );
};

export default AddApplicableTailsSection;
