/***
 * Copyright (C) 2025 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Update Page Approval Flow
 */
import styled from '@emotion/styled';
import {Button} from '@mui/material';
import {colors, spacing} from '@vst/beam';
import {difference, mapValues} from 'lodash';
import moment, {Moment} from 'moment';
import React from 'react';
import {Approval} from '../api/queries/approvals';
import {PackageData} from '../api/queries/packages';
import {ApprovalForm} from './ApprovalForm';
import Loading from './Loading';
import Error from './Error';
import {UpdateStatus} from './UpdateStatus';
import {Section} from './Section';
import {formatPackageType} from '../utils/gridUtils';
import {strings} from '../locale/strings';
import {UseMutationResult} from '@tanstack/react-query';
import {CreateApprovalVars} from '../api/mutations/useCreateApproval';
import useBearStore from '../utils/stores/useBearStore';

const UpdateStatusContainer = styled.div`
  padding: ${spacing[24]} ${spacing[32]} ${spacing[48]} ${spacing[32]};
`;

const UpdateStatusWaitingContainer = styled.div`
  margin: ${spacing[16]} ${spacing[24]} ${spacing[24]} ${spacing[32]};
`;

const lockedPackageTypes = ['portal', 'ife']; // Changing tails or deployment date for these package types isn't allowed
export const ApprovalFlow: React.FC<{
  update: PackageData;
  approvals: Approval[];
  createApprovalResult: UseMutationResult<any, unknown, CreateApprovalVars, unknown>;
}> = ({update, approvals, createApprovalResult}) => {
  const {approvalAirlineCodes, name} = useBearStore(state => state.init); 

  const firstPossibleDeploymentDate = moment().utc(); // today (UTC)
  const applicableButNotApprovedFleetsTails = mapValues(update.applicable_tails, values => difference(values, ...approvals.map(approval => approval.approved_tails)));
  
  /**
   * Callback for when form "Approve" button is clicked.
   *
   * @param approvedTails Set of approved tails.
   * @param deploymentDate The deployment date.
   */
  const onApprove = (approvedTails: Array<string>, deploymentDate: Moment) => {
    createApprovalResult.mutate({
      updateId: update.id,
      params: {
        approval_type: 'approval',
        approved_tails: approvedTails,
        deployment_date: deploymentDate.format('YYYY-MM-DD')
      }
    });
  };

  /**
   * Callback for when form "Reject" button is clicked.
   *
   * @param rejectionReason The reason for rejection.
   */
  const onReject = (rejectionReason: string) => {
    createApprovalResult.mutate({
      updateId: update.id,
      params: {
        approval_type: 'rejection',
        reason: rejectionReason
      }
    });
  };

  if (createApprovalResult.isLoading) {
    return <Loading />;
  }

  if (createApprovalResult.error) {
    console.error(JSON.stringify(createApprovalResult.error));
    return <Error text={strings.SomethingWentWrongTryRefreshingYourBrowser} />;
  }

  if (update.status === 'rejected') {
    return (
      <UpdateStatusContainer>
        <UpdateStatus
          icon="infoIcon"
          title={strings.ApprovalFlow.UpdateHasBeenRejected}
          bodyText={strings.ApprovalFlow.UpdateRejected.BodyText}
        />
      </UpdateStatusContainer>
    );
  }
  
  if (update.status === 'closed') {
    return (
      <UpdateStatusContainer>
        <UpdateStatus
          icon="infoIcon"
          title={strings.ApprovalFlow.UpdateIsUnavailable}
          bodyText={strings.ApprovalFlow.UpdateClosed.BodyText}
        />
      </UpdateStatusContainer>
    );
  }

  if (!Object.values(update.applicable_tails).length) {
    return (
      <UpdateStatusContainer>
        <UpdateStatus
          icon="infoIcon"
          title={strings.ApprovalFlow.UpdateIsUnavailable}
          bodyText={strings.ApprovalFlow.UpdateUnavailable.BodyText}
        />
      </UpdateStatusContainer>
    );
  }

  if (update.status === 'fully_approved') {
    return (
      <UpdateStatusContainer>
        <UpdateStatus
          icon="done"
          title={strings.ApprovalFlow.UpdateIsFullyApproved}
          bodyText={strings.ApprovalFlow.UpdateFullyApproved.BodyText}
        />
      </UpdateStatusContainer>
    );
  }

  if (createApprovalResult.data) {
    return (
      <UpdateStatusContainer>
        <UpdateStatus
          icon="done"
          title={strings.ApprovalFlow.UpdateFullyApproved.Title}
          bodyText={strings.ApprovalFlow.UpdateFullyApproved.BodyText}
        >
          <Button
            variant="contained"
            onClick={() => {
              createApprovalResult.reset();
            }}
          >
            {strings.ApprovalFlow.ApproveForMoreTails}
          </Button>
        </UpdateStatus>
      </UpdateStatusContainer>
    );
  }

  if (approvalAirlineCodes?.includes(update.airline_code)) {
    return (
      <div
        style={{
          maxWidth: '780px',
          padding: `${spacing[16]} ${spacing[32]} 0px`,
          display: 'flex',
          flexDirection: 'column',
          gap: spacing[32]
        }}
      >
        <Section
          title={strings.ApprovalFlow.ApproveUpdate.Title}
          bodyText={(lockedPackageTypes.includes(update.package_type)
            ? strings.ApprovalFlow.ApproveUpdate.BodyTextLockedPackageType
            : strings.ApprovalFlow.ApproveUpdate.BodyTextNotLockedPackageType
          )
            .replace('%package_type%', formatPackageType(update.package_type))
            .replace('%package_name%', update.name)}
          bodyTextColor={colors.gray[800]}
          layout="vertical"
        >
          <ApprovalForm
          approverName={name}
          update={update}
          firstPossibleDeploymentDate={firstPossibleDeploymentDate}
          possibleFleetsTails={applicableButNotApprovedFleetsTails}
          onApprove={onApprove}
          onReject={onReject}
          />
        </Section>
      </div>
    );
  }

  return (
    <UpdateStatusWaitingContainer>
      <Section
        title={strings.ApprovalFlow.UpdateWaitingForApprovals.Title}
        bodyText={strings.ApprovalFlow.UpdateWaitingForApprovals.BodyText}
        bodyTextColor={colors.gray[800]}
      />
    </UpdateStatusWaitingContainer>
  );
};
