/***
 * Copyright (C) 2025 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: ApprovalsTable component
 */

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {SxProps, Table, TableCell, TableContainer, TableHead, TableRow as MUITableRow, Theme} from '@mui/material';
import {colors, spacing} from '@vst/beam';
import {Approval} from '../api/queries/approvals';
import {PackageData} from '../api/queries/packages';
import {Dispatcher} from '../theme/Colors';
import {formatApprovedTailsCount, formatPackageType} from '../utils/gridUtils';
import ApprovalTypeAndTime from './ApprovalTypeAndTime';
import IconTextInABox from './IconTextInABox';
import {HeaderCell, TableBody, TextCell} from './Tables';
import {FilterRow} from '@viasat/insights-components/dist/components/filterSelector/FilterSelectorContainer';
import {filterApprovals} from './ApprovalsFilter';

const ApprovalsTableHead: React.FC<{displayAirlineColumn: boolean}> = ({displayAirlineColumn}) => (
  <TableHead>
    <MUITableRow>
      <HeaderCell text="Approvals & Rejections" />
      {displayAirlineColumn && <HeaderCell text="Airline" />}
      <HeaderCell text="Approver" />
      <HeaderCell text="Type" />
      <HeaderCell text="Update" />
      <HeaderCell text="Approved" />
      <HeaderCell text="" sx={{width: '99%'}} />
    </MUITableRow>
  </TableHead>
);

const ApprovalsTableRow: React.FC<{
  approval: Approval & {update: PackageData};
  onClick: (approval: Approval & {update: PackageData}) => void;
  displayAirlineColumn: boolean;
  selected: boolean;
}> = ({approval, onClick, displayAirlineColumn, selected}) => (
  <MUITableRow
    sx={{
      '&:hover': {backgroundColor: colors.gray[100]},
      cursor: 'pointer'
    }}
    onClick={() => onClick(approval)}
    selected={selected}
  >
    <TableCell>
      <ApprovalTypeAndTime approvalType={approval.approval_type} createdTimestamp={approval.created_timestamp} />
    </TableCell>
    {displayAirlineColumn && <TextCell text={approval.update.airline_name} />}
    <TextCell text={approval.creator_name} sx={{maxWidth: 200}} ellipsis />
    <TextCell text={formatPackageType(approval.update.package_type)} />
    <TextCell text={approval.update.name} />
    <TextCell
      color={Dispatcher.AccessibleGray}
      text={formatApprovedTailsCount(approval.approved_tails, Object.values(approval.update.applicable_tails).flat())}
      sx={{textAlign: 'right'}}
    />
    <TableCell align="right" sx={{whiteSpace: 'nowrap'}}>
      <ArrowForwardIcon
        htmlColor={Dispatcher.AccessibleGrayIconsAndBorders}
        sx={{verticalAlign: 'middle', width: 24, height: 24}}
      />
    </TableCell>
  </MUITableRow>
);

const ApprovalsTableEmptyRow: React.FC<{numCols: number}> = ({numCols}) => (
  <MUITableRow>
    <TableCell colSpan={numCols + 1} style={{paddingTop: `${spacing[32]}`, paddingBottom: `${spacing[40]}`}}>
      <IconTextInABox icon="search" text="No updates found. Try adjusting your filters." />
    </TableCell>
  </MUITableRow>
);

// Sort descending by creation time
const defaultSortFn = (a: Approval, b: Approval) =>
  new Date(b.created_timestamp).getTime() - new Date(a.created_timestamp).getTime();

/**
 * Table of Update Package Approvals
 */
const ApprovalsTable: React.FC<{
  approvals?: (Approval & {update: PackageData})[];
  filters: FilterRow[];
  isLoading: boolean;
  error: boolean;
  onClickApprovalRow: (approval: Approval & {update: PackageData}) => void;
  displayAirlineColumn: boolean;
  selectedApprovalId?: string;
  sx?: SxProps<Theme>;
}> = ({approvals, filters, isLoading, error, onClickApprovalRow, displayAirlineColumn, selectedApprovalId, sx}) => {
  const numCols = displayAirlineColumn ? 6 : 5;
  return (
    <TableContainer sx={{flex: 1, paddingRight: 0, ...sx}}>
      <Table>
        <ApprovalsTableHead displayAirlineColumn={displayAirlineColumn} />
        <TableBody
          items={filters.length > 0 ? filterApprovals(filters, approvals || []) : approvals}
          isLoading={isLoading}
          error={error}
          numCols={numCols}
          defaultSortFn={defaultSortFn}
          renderRow={approval => (
            <ApprovalsTableRow
              key={approval.id}
              approval={approval}
              selected={selectedApprovalId === approval.id}
              displayAirlineColumn={displayAirlineColumn}
              onClick={onClickApprovalRow}
            />
          )}
          renderEmpty={() => <ApprovalsTableEmptyRow numCols={numCols} />}
        />
      </Table>
    </TableContainer>
  );
};
export default ApprovalsTable;
