/***
 * Copyright (C) 2025 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: MultiSelectorDropdown component
 */

import {Search} from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Select,
  SxProps,
  Theme,
  Typography,
  Box,
  Stack
} from '@mui/material';
import {isEqual, sortBy, isEqualWith} from 'lodash';
import React, {ReactNode, useCallback, useMemo, useState} from 'react';
import {Dispatcher} from '../theme/Colors';
import {spacing} from '@vst/beam';
import {outlinedInputClasses} from '@mui/material/OutlinedInput';

interface CheckedItemsState {
  [key: string]: string[];
}

const Empty: React.FC<{}> = () => (
  <Stack direction="column" justifyContent="center" alignItems="center" height="176px" gap="16px">
    <Search color="primary" fontSize="large" />
    <Typography style={{textAlign: 'center'}}>
      Try searching for something else.
      <br />
      Only tails applicable for the update can be searched.
    </Typography>
  </Stack>
);

/**
 * Select from multiple items
 */
interface MultiSelectorDropdownProps {
  prompt: string;
  label?: string;
  helperText?: string | ReactNode;
  selectAllLabel?: string;
  saveSelectionLabel?: string;
  renderFooter?: () => ReactNode;
  error?: boolean;
  fullWidth?: boolean;
  helperTextSx?: SxProps<Theme>;
  selectAllByDefault?: boolean;
  items: Record<string, string[]>;
  selectedItems: Record<string, string[]>;
  setSelectedItems: (items: Record<string, string[]>) => void;
}

const MultiSelectorDropdown: React.FC<MultiSelectorDropdownProps> = ({
  items,
  selectedItems,
  setSelectedItems,
  prompt,
  label,
  helperText,
  selectAllLabel,
  saveSelectionLabel,
  renderFooter,
  error,
  fullWidth = true,
  helperTextSx,
  selectAllByDefault = true
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>('');
  const [checkedItems, setCheckedItems] = useState<Record<string, string[]>>(
    selectAllByDefault ? items || {} : selectedItems
  );

  /**
   * Memoized function to check if all tails are selected.
   *
   * This function uses `useMemo` to optimize performance by memoizing the result
   * of the comparison between `checkedItems` and `items`. It uses
   * `isEqualWith` from lodash to perform a deep comparison, with a customizer
   * function that sorts arrays before comparing them.
   *
   * @returns {boolean} - Returns `true` if all tails are selected, otherwise `false`.
   *
   * Dependencies:
   * - `checkedItems`: The list of currently checked items.
   * - `items`: The list of all available tails in the airline fleet.
   */
  const allTailsSelected = useMemo(
    () =>
      isEqualWith(checkedItems, items, (objValue, othValue) => {
        if (Array.isArray(objValue)) {
          return isEqual(sortBy(objValue), sortBy(othValue));
        }
      }),
    [checkedItems, items]
  );

  /**
   * Filters the `items` object based on the `searchString`.
   *
   * @returns {Record<string, string[]>} A filtered object where each key is a parent and the value is an array of children that include the `searchString`.
   *
   * @param {Record<string, string[]>} items - The object containing parent keys and arrays of children strings.
   * @param {string} searchString - The string to filter the children by.
   *
   * @example
   *  Given items = { "Fleet1": ["Tail1", "Tail2"], "Fleet2": ["Tail3", "Tail4"] }
   *  and searchString = "Tail1"
   *  The result will be { "Fleet1": ["Tail1"] }
   */
  const filteredItems = useMemo(() => {
    if (!items) return {};
    const results: Record<string, string[]> = {};
    Object.keys(items).forEach(parent => {
      if (parent.toLowerCase().includes(searchString.toLowerCase())) {
        results[parent] = items[parent];
      } else {
        const filteredChildren = items[parent].filter(child =>
          child.toLowerCase().includes(searchString.toLowerCase())
        );
        if (filteredChildren.length > 0) {
          results[parent] = filteredChildren;
        }
      }
    });

    return results;
  }, [items, searchString]);

  /**
   * Handles the change event for the search input field.
   * Updates the search string state with the current value of the input field.
   *
   * @param event - The change event triggered by the input field.
   */
  const onSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value);
  }, []);

  /**
   * Handles the change event for selecting or deselecting all items in the dropdown.
   *
   * @param event - The change event triggered by the checkbox input.
   * @param checked - A boolean indicating whether the checkbox is checked or not.
   */
  const onSelectAllChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setCheckedItems(checked ? items || {} : {});
    },
    [items]
  );

  /**
   * Handles the change event for a parent checkbox in the multi-selector dropdown.
   *
   * @param event - The change event triggered by the checkbox.
   * @param parentName - The name of the parent item associated with the checkbox.
   *
   * When the checkbox is checked, it adds the parent item and its associated fleet tails to the checked items state.
   * When the checkbox is unchecked, it removes the parent item from the checked items state.
   */
  const handleParentChange = (event: React.ChangeEvent<HTMLInputElement>, parentName: string) => {
    if (event.target.checked) {
      setCheckedItems(prev => ({...prev, [parentName]: items ? items[parentName] : []}));
    } else {
      setCheckedItems(prev => {
        const newCheckedItems = {...prev};
        delete newCheckedItems[parentName];
        return newCheckedItems;
      });
    }
  };

  /**
   * Handles the change event for a child checkbox in a multi-selector dropdown.
   *
   * @param event - The change event triggered by the checkbox.
   * @param parentName - The name of the parent item to which the child belongs.
   * @param child - The name of the child item being checked or unchecked.
   */
  const handleChildChange = (event: React.ChangeEvent<HTMLInputElement>, parentName: string, child: string) => {
    if (event.target.checked) {
      setCheckedItems((prev: CheckedItemsState) => ({
        ...prev,
        [parentName]: [...(prev[parentName] || []), child]
      }));
    } else {
      setCheckedItems((prev: CheckedItemsState) => ({
        ...prev,
        [parentName]: (prev[parentName] || []).filter(tail => tail !== child)
      }));
    }
  };

  const renderMultiSelectorDropdownCheckBox = (parentName: string): JSX.Element => {
    const selectedItems = checkedItems[parentName] || [];
    const allChildrenSelected = selectedItems.length === (items?.[parentName] || []).length;
    const someChildrenSelected = selectedItems.length > 0 && !allChildrenSelected;

    return (
      <Box key={parentName} data-testid={parentName}>
        <FormControlLabel
          control={
            <Checkbox
              checked={allChildrenSelected}
              indeterminate={someChildrenSelected}
              onChange={event => handleParentChange(event, parentName)}
            />
          }
          label={
            <Typography variant="body1" sx={{fontWeight: 'bold'}}>
              {parentName}
            </Typography>
          }
        />
        <FormGroup
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            marginLeft: '10px',
            maxHeight: '300px',
            overflow: 'auto'
          }}
        >
          {filteredItems[parentName].map(child => (
            <FormControlLabel
              sx={{flex: "0 0 100px" , minWidth: "100px", maxWidth: "110px" }}
              key={child}
              control={
                <Checkbox
                  checked={selectedItems.includes(child)}
                  onChange={event => handleChildChange(event, parentName, child)}
                />
              }
              label={child}
            />
          ))}
        </FormGroup>
      </Box>
    );
  };

  const renderFilteredItems = () => {
    if (Object.keys(filteredItems).length > 0) {
      return Object.keys(filteredItems).map(parentName => renderMultiSelectorDropdownCheckBox(parentName));
    } else {
      return <Empty />;
    }
  };

  return (
    <FormControl
      fullWidth={fullWidth}
      aria-describedby="component-helper-text"
      required
      sx={{
        [`& .${outlinedInputClasses.input}`]: {
          padding: `${spacing[16]} ${spacing[12]} ${spacing[16]} ${spacing[12]}`
        }
      }}
    >
      {label && (
        <InputLabel id="input-label" error={error} shrink={true}>
          {label}
        </InputLabel>
      )}
      <Select
        label={label ?? ''}
        labelId="input-label"
        notched={Boolean(label)}
        error={error}
        displayEmpty
        native={false}
        renderValue={() => <Typography color={Dispatcher.AccessibleGray}>{prompt}</Typography>}
        onOpen={x => {
          setOpen(true);
          setCheckedItems(selectedItems);
        }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <Stack spacing={2} sx={{padding: `${spacing[8]} ${spacing[16]}`}}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{marginBottom: '10px'}}>
            <FormControl variant="outlined">
              <OutlinedInput
                type="text"
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton edge="start">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                }
                size="small"
                placeholder="Search"
                sx={{
                  borderRadius: spacing[28],
                  input: {
                    '&::placeholder': {
                      color: Dispatcher.AccessibleGray,
                      opacity: '1'
                    }
                  }
                }}
                value={searchString}
                onChange={onSearchChange}
              />
            </FormControl>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox onChange={onSelectAllChange} />}
                label={selectAllLabel}
                checked={allTailsSelected}
              />
            </FormGroup>
          </Stack>
          <Divider />
          {renderFilteredItems()}
          <Divider />
          <Stack direction="column" justifyContent="center" alignItems="center">
            {renderFooter && Object.keys(checkedItems).length > 0 && (
              <Box sx={{padding: `${spacing[16]} 0`}}>{renderFooter()}</Box>
            )}
            <Button
              sx={{margin: `${spacing[16]} 0`}}
              size="large"
              variant="contained"
              onClick={() => {
                setSelectedItems(checkedItems);
                setOpen(false);
              }}
            >
              {saveSelectionLabel}
            </Button>
          </Stack>
        </Stack>
      </Select>
      {helperText && (
        <FormHelperText component="div" sx={{...helperTextSx}} error={error} id="component-helper-text">
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

MultiSelectorDropdown.defaultProps = {
  selectAllLabel: 'Select all',
  saveSelectionLabel: 'Save selection'
};
export default MultiSelectorDropdown;
